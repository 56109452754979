import { createAsyncThunk } from "@reduxjs/toolkit";
import { duplicateBom } from "api/bom";
import { pasteInstructions } from "api/instruction";
import { RootState } from "store";

export const pasteThunk = createAsyncThunk(
  "clipboard/paste",
  async ({ name, destination }: { name: string; destination: string }, { rejectWithValue, getState }) => {
    try {
      const clipboard = (getState() as RootState).clipboard.values?.find((i) => i.name === name);
      if (!clipboard) {
        return rejectWithValue("not found");
      }
      if (name === "bom") {
        await duplicateBom({
          originBom: clipboard.origin,
          destinationItem: destination,
        });
        return { name };
      }
      if (name === "instruction-cluster") {
        await pasteInstructions({
          sourceClusterId: clipboard.origin,
          destinationClusterId: destination,
          InstructionIds: clipboard.value || [],
        });
        return { name };
      }
      if (name === "instruction-device") {
        await pasteInstructions({
          sourceDeviceId: clipboard.origin,
          destinationDeviceId: destination,
          InstructionIds: clipboard.value || [],
        });
        return { name };
      }

      return rejectWithValue("unknown name");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
